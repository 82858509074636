exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-templates-news-cat-template-js": () => import("./../../../src/templates/news-cat-template.js" /* webpackChunkName: "component---src-templates-news-cat-template-js" */),
  "component---src-templates-news-post-template-js": () => import("./../../../src/templates/news-post-template.js" /* webpackChunkName: "component---src-templates-news-post-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-tech-blog-post-template-js": () => import("./../../../src/templates/tech-blog-post-template.js" /* webpackChunkName: "component---src-templates-tech-blog-post-template-js" */),
  "component---src-templates-tech-blog-template-js": () => import("./../../../src/templates/tech-blog-template.js" /* webpackChunkName: "component---src-templates-tech-blog-template-js" */),
  "component---src-templates-tech-cat-template-js": () => import("./../../../src/templates/tech-cat-template.js" /* webpackChunkName: "component---src-templates-tech-cat-template-js" */)
}

